/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */

import { useCallback, useEffect, useState } from 'react';
import { MediaRecorder } from 'extendable-media-recorder';
import sentry from './sentry';
import UploadService from './uploader';
import { ackRecording } from './db';

const MD = () => navigator.mediaDevices;

const audio = {
  autoGainControl: false,
  echoCancellation: false,
  noiseSuppression: false,
  sampleRate: 16000,
  sampleSize: 16,
  channelCount: 1,
};

export const useMicSelector = () => {
  const [deviceId, _setDeviceId] = useState();

  const setDeviceId = useCallback((dev) => {
    localStorage.setItem('inputdevice', dev);
    _setDeviceId(dev);
  }, []);

  useEffect(() => {
    _setDeviceId(localStorage.getItem('inputdevice'));
  }, []);

  return { deviceId, setDeviceId };
};

const getUserMedia = (constraints) => new Promise((resolve, deny) => {
  if (MD() && MD().getUserMedia) {
    MD().getUserMedia(constraints)
      .then(resolve, deny);
  } if (navigator.getUserMedia) {
    navigator.getUserMedia(constraints, resolve, deny);
  } else if (navigator.webkitGetUserMedia) {
    navigator.webkitGetUserMedia(constraints, resolve, deny);
  } else if (navigator.mozGetUserMedia) {
    navigator.mozGetUserMedia(constraints, resolve, deny);
  } else {
    deny(new Error('not implemented'));
  }
});

const findDevice = async (deviceId, idevices = []) => {
  let devices = idevices.filter(d => d.kind === 'audioinput' && d.deviceId);
  // no MediaDevices so user cant select mic
  if (!MD()) return null;

  if (!devices.length) {
    const stream = await getUserMedia({ audio });
    // get devs list
    devices = await MD().enumerateDevices();
    devices = devices.filter(d => d.kind === 'audioinput' && d.deviceId);
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    try {
      stream.stop();
    } catch {}
  }
  let selectedDeviceId;

  if (deviceId) {
    selectedDeviceId = devices.find((d) => d.deviceId === deviceId) || devices[0];
  } else {
    selectedDeviceId = devices[0];
  }
  return {
    selectedDeviceId,
    finalDevices: devices,
  };
};

export const getConstraints = async (deviceId, devices) => {
  const { selectedDeviceId, finalDevices } = await findDevice(deviceId, devices);

  if (selectedDeviceId) {
    // return found device (selected or first)
    return {
      constraints: {
        audio: { ...audio, deviceId: { exact: selectedDeviceId.deviceId } },
      },
      finalDevices,
    };
  }
  return {
    constraints: { audio },
    finalDevices,
  };
};

export const initMicrophone = async (deviceId) => {
  // Request audio access only

  // get devs list
  try {
    const devices = await MD().enumerateDevices();
    const { constraints , finalDevices } = await getConstraints(deviceId, devices);
    const stream = await MD().getUserMedia(constraints);
    return { stream, devices: finalDevices };
  } catch (err) {
    console.error(err);
    const stream = await getUserMedia({ audio });
    return { stream, devices: [] };
  }
};

// console.log('-- All supported Audios : ', supportedAudios);

export class Recorder {
  constructor() {
    this.recorderListeners = {
      start: null,
      dataavailable: null,
      stop: null,
    };
    this.uploader = new UploadService();
    this.lastChunk = false;
    this.partNumber = 0;
    this.clock = 0;
  }

  /**
   * @param  {string[]} types           String array of types
   * @param  {string[]} codecs          String array of codecs
   *
   * @return {string[]}                 Array of accepted "mimetype;codec"
   */
  getSupportedMimeTypes() {
    const types = ['webm', 'ogg', 'mp3', 'x-matroska'];
    const codecs = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus', 'pcm', 'aac', 'mpeg', 'mp4a'];

    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach((type) => {
      const mimeType = `audio/${type}`;
      codecs.forEach((codec) => [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs:${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
        `${mimeType};codecs:${codec.toUpperCase()}`,
      ].forEach((variation) => {
        if (isSupported(variation)) { supported.push(variation); }
      }));
      if (isSupported(mimeType)) { supported.push(mimeType); }
    });
    return supported;
  }

  isReady() {
    return !!this.microphone;
  }

  async getMicrophone(deviceId) {
    const { constraints } = await getConstraints(deviceId);
    return getUserMedia(constraints);
  }

  setVolumeCallback(cb) {
    this.volumeCallback = cb;
  }

  async init(deviceId) {
    if (this.isReady()) {
      return;
    }

    const microphone = await this.getMicrophone(deviceId);
    this.microphone = microphone;

    const supportedAudios = this.getSupportedMimeTypes();
    const supportedWAV = supportedAudios.find((s) => s.toLowerCase().indexOf('wav') > -1) || supportedAudios.find((s) => s.toLowerCase().indexOf('pcm') > -1);

    if (!supportedWAV) {
      alert('No WAVE/PCM codec supported for this browser, please try latest Chrome or Firefox');
      throw Error('NO WAV codec');
    } else {
      console.log('Codec found', supportedWAV);
    }

    this.recorder = new MediaRecorder(microphone, { mimeType: 'audio/wav' });
    this.sessionId = await this.uploader.start();
    return this.sessionId;
  }

  async start(onLastSuccess, onFinalLengthAvail) {
    if (!this.isReady()) {
      sentry('Cannot record audio before microhphone is ready.');
      return Promise.resolve();
    }

    return new Promise((res) => {
      // Remove the old listeners.
      this.recorder.removeEventListener('start', this.recorderListeners.start);
      this.recorder.removeEventListener('dataavailable', this.recorderListeners.dataavailable);
      // Update the stored listeners.
      this.recorderListeners.start = (e) => {
        console.log('recording started', e);
        this.timerId = setInterval(() => {
          ++this.clock;
        }, 1000);
        res();
      };
      this.recorderListeners.dataavailable = async (e) => {
        this.partNumber += 1;
        const currentClock = this.clock;
        this.clock = 0;
        const dbRes = await Promise.all([
          this.uploader.upload(this.partNumber, e.data, this.lastChunk, onLastSuccess),
          ackRecording(currentClock),
        ])[1];

        if (this.lastChunk) {
          onFinalLengthAvail(dbRes.recordingLength)
        }
      };
      // Add the new listeners.
      this.recorder.addEventListener('start', this.recorderListeners.start);
      this.recorder.addEventListener('dataavailable', this.recorderListeners.dataavailable);

      this.recorder.start(90 * 1000);
    });
  }

  stop() {
    if (!this.isReady()) {
      console.log('Cannot stop audio before microphone is ready.');
      return Promise.resolve();
    }

    this.lastChunk = true;

    return new Promise((res) => {
      this.recorder.removeEventListener('stop', this.recorderListeners.stop);
      this.recorderListeners.stop = (e) => {
        if (this.timerId) {
          clearInterval(this.timerId);
        }
        console.log('recording stopped', e);
        console.log('total recording length:', this.clock);
        res(this.clock);
        this.clock = 0;
      };
      this.recorder.addEventListener('stop', this.recorderListeners.stop);
      try {
        this.recorder.stop();
      } catch (err) {
        console.error('could not stop the recording', err);
      }
    });
  }

  release() {
    if (this.microphone) {
      // eslint-disable-next-line no-restricted-syntax
      for (const track of this.microphone.getTracks()) {
        track.stop();
      }
    }
    this.microphone = null;
  }
}
