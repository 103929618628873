import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import trophy from '../lotties/trophy.json';
import { getLeadership, getLocalMetadata } from '../db';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getHumanReadableTime = (duration, cap = false) => {
  const readableString = moment.duration(duration, 'second').humanize();
  if (cap) {
    return capitalizeFirstLetter(readableString);
  }
  return readableString;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const LeadershipModal = ({ onClose }) => {
  const classes = useStyles();
  const [leaderboard, setLeaderboard] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getLeadership();
      await new Promise((res) => {
        setTimeout(res, 2000);
      });
      setLeaderboard(res);
    };
    fetchData();
  }, []);

  const { hash } = getLocalMetadata();
  return (
    <Modal
      className={classes.modal}
      open={true}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Dialog fullWidth maxWidth="sm" open={true}>
        <DialogTitle id="leaderboard-modal">Leaderboard</DialogTitle>
        <DialogContent>
          {leaderboard.length ? (
            <List component="nav">
              {leaderboard.map((leader, index) => (
                <ListItem button key={leader.userHash}>
                  <ListItemIcon>{leader.userHash === hash && <ArrowForwardIcon />}</ListItemIcon>
                  <ListItemText
                    primary={`Rank: ${index + 1} | ${leader.firstname} ${leader.lastname}`}
                    secondary={`Recorded: ${getHumanReadableTime(leader.recordingLength, true)}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: trophy,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={200}
              width={200}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default LeadershipModal;
