import { useCallback, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import awsconfig from './aws-exports';
import sentry from './sentry';

Amplify.configure(awsconfig);

export const useAuth = () => {
  const [user, setUser] = useState();
  const [signing, setSigning] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!user) {
      setError(false);
      setLoading(true);
      Auth.currentAuthenticatedUser().then((u) => {
        if (u) { setUser({ username: u.username }); setLoading(false); }
      }).catch((err) => {
        setLoading(false);
        // sentry(err);
        // setError(err.message);
      });
    }
  }, [user]);

  const signIn = useCallback(async (groupId) => {
    setError(false);
    setSigning(true);
    window.localStorage.setItem('groupId', groupId);

    try {
      const password = sha256(groupId).toString();
      let u = await Auth.signIn(
        groupId,
        password,
      );

      if (u.challengeName === 'NEW_PASSWORD_REQUIRED') {
        u = await Auth.completeNewPassword(u, password);
      }
      if (u) {
        setUser({ username: u.username });
        setLoading(false);
        setSigning(false);
      } else {
        setError("Group ID doesn't exist");
      }
      return u;
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setError("Group ID doesn't exist");
      } else {
        sentry('error signing up:', err);
        setError(err.message);
      }
      setSigning(false);
    }
  }, [setLoading, setSigning]);

  const signOut = useCallback((cb) => {
    setError(false);
    setLoading(true);
    window.localStorage.removeItem('groupId');
    Auth.signOut().then(() => {
      if (cb) cb();
      setUser();
      setLoading(false);
    }).catch((err) => setError(err.message));
  }, [setUser]);

  return {
    user, signIn, signOut, signing, loading, error, groupId: window.localStorage.getItem('groupId')
  };
};

export const getAuth = () => ({
  groupId: window.localStorage.getItem('groupId'),
});
