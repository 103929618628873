/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useContext, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Button, makeStyles } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import MicIcon from '@material-ui/icons/MicNone';
import Context from './Context';
import { Recorder } from './audio';
import sentry from './sentry';
import wave from './lotties/wave.json';
import uploadingAnimation from './lotties/uploading.json';
import successAnimation from './lotties/success.json';
import Timer from './components/Timer';
import { getHumanReadableTime } from './components/Leaderboard';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0),
    width: '100%',
    textAlign: 'center',
  },
  mic: {
    margin: '20px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  please: {
    fontWeight: 'bolder',
  },
  timer: {
    display: 'inline-flex',
    width: '90px',
  },
}));

const RecorderApp = () => {
  const classes = useStyles();
  const [recording, setRecording] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [totalRecordingLength, settotalRecordingLength] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { deviceId } = useContext(Context);

  const recorder = useRef();

  const record = useCallback(() => {
    if (recording) {
      // Stop recording
      setRecording(false);
      setSubmitting(true);
      recorder.current.stop().then(async () => {
        recorder.current.release();
        recorder.current = null;
      });
    } else {
      // Start recording
      setSubmitSuccess(false);
      setInitializing(true);
      if (recorder.current) {
        recorder.current.stop();
        recorder.current.release();
      }
      recorder.current = new Recorder();
      recorder.current
        .init(deviceId)
        .then(() => {
          setInitializing(false);
          recorder.current.start(() => {
            setSubmitting(false);
            setSubmitSuccess(true);
          }, (rl) => {
            settotalRecordingLength(rl);
          }).then(() => {
            setRecording(true);
          });
        })
        .catch((err) => sentry(err));
    }
  }, [recorder, recording, deviceId]);

  console.log('recorder app rendering...');
  return (
    <div className={classes.submit}>
      <Button
        variant="contained"
        onClick={record}
        color="secondary"
        className={classes.mic}
        disabled={initializing || submitting}
      >
        {recording ? <StopIcon fontSize="large" /> : <MicIcon fontSize="large" />}
      </Button>

      {(recording || (!initializing && !submitSuccess && !submitting)) && (
        <>
          <Lottie
            options={{
              loop: true,
              autoplay: false,
              animationData: wave,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={400}
            width={400}
            isPaused={!recording}
          />
          {recording && <Timer /> }
        </>
      )}

      {(initializing || submitting || submitSuccess) && (
        <>
          <Lottie
            options={{
              loop: initializing || submitting,
              autoplay: true,
              animationData: initializing || submitting ? uploadingAnimation : successAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={400}
            width={400}
          />
          {initializing && 'Initializing...'}
          {submitting && 'Finalizing the recording...'}
          {submitSuccess && totalRecordingLength && <div>Recorded {getHumanReadableTime(totalRecordingLength)} today</div>}
        </>
      )}
    </div>
  );
};

export default React.memo(RecorderApp);
