import React, { useState } from 'react';
import Lottie from 'react-lottie';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { FormControl, Grid, InputLabel, makeStyles, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import uploadingAnimation from '../lotties/uploading.json';
import { storeMetadata } from '../db';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
  selectbox: {
    width: '100%',
  },
}));

const ACCENTS = [
  'Assamese',
  'Bengali (Bangla)',
  'Bodo',
  'Dogri',
  'Gujarati',
  'Hindi',
  'Kannada',
  'Kashmiri',
  'Konkani',
  'Maithili',
  'Malayalam',
  'Meitei (Manipuri)',
  'Marathi',
  'Nepali',
  'Odia',
  'Punjabi',
  'Sanskrit',
  'Santali',
  'Sindhi',
  'Tamil',
  'Telugu',
  'Urdu',
  'Other',
];

export default function MetadataForm({ onNext }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const { handleSubmit, control } = useForm();

  const onSubmit = async (d) => {
    setSaving(true);
    await storeMetadata(d);
    setSaving(false);
    onNext(d);
  };

  if (saving) {
    return (
      <>
        <Container maxWidth="sm">
          <Box my={4}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: uploadingAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={400}
              width={400}
            />
            <div className={classes.submit}>Saving your information...</div>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <form id="metadata-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="First Name"
                autoComplete="firstname"
                autoFocus
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Last Name"
                autoComplete="lastname"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'gender',
                    id: 'gender',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="age"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="age">Age</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'age',
                    id: 'age',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  <option>18-30</option>
                  <option>30-50</option>
                  <option>50+</option>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Controller
            name="accent"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="accent">Dialect</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'accent',
                    id: 'accent',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  {ACCENTS.map((accent) => (
                    <option key={accent} value={accent.split(' ')[0]}>
                      {accent}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.submit}>
        <Button type="submit" variant="contained" color="primary" id="metadata-next">
          Next
        </Button>
      </div>
    </form>
  );
}
