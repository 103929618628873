import React, { useContext, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Context from '../src/Context';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import PeopleIcon from '@material-ui/icons/People';
import { green } from '@material-ui/core/colors';
import LoginForm from './components/LoginForm';
import Recorder from './Recorder';
import MicSelector from './components/MicSelector';
import MetadataForm from './components/MetadataForm';
import LeadershipModal from './components/Leaderboard';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
  trymic: {
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  trymiccont: {
    width: '100%',
    minHeight: '100px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  welcome: {
    textAlign: 'center',
  },
  welcomebtn: {
    marginTop: '20px',
    minWidth: '150px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function CallCenterApp() {
  const classes = useStyles();
  const { user, loading, groupId } = useContext(Context);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [isMetadataAvailable, setIsMetadataAvailable] = useState(!!window.localStorage.getItem(`metadataV3_${groupId}`))

  if (loading) return (
    <Container maxWidth="sm" className={classes.welcome}>
      <CircularProgress />
    </Container>
  );

  if (!user)
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <LoginForm />
        </Box>
      </Container>
    );

  if (!isMetadataAvailable) {
    return (
      <Container maxWidth="md">
        <Box my={6}>
          <MetadataForm onNext={() => { setIsMetadataAvailable(true) }}/>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box my={4}>
          <div className={classes.trymic}>
            <MicSelector />
          </div>
          <Recorder />
        </Box>
      </Container>
      <Fab className={clsx(classes.fab, classes.fabGreen)} color="inherit" onClick={() => { setShowLeaderboard(true); }}>
        <PeopleIcon />
      </Fab>
      { showLeaderboard && <LeadershipModal onClose={() => { setShowLeaderboard(false); }} />}
    </>
  );
}

export default CallCenterApp;
