/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import {
  AppBar, Button, makeStyles, Toolbar,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Context from '../Context';

const useStyles = makeStyles((theme) => ({
  topbar: {
    background: 'transparent!important',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '60px',
    },
  },
  title: {
    flexGrow: 1,
  },
  button: {
    color: 'black',
    minWidth: '10px',
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const { user, signOut } = useContext(Context);

  const logout = (e) => {
    e.preventDefault();
    signOut(() => {

    });
  };
  return (
    <AppBar position="absolute" className={classes.topbar}>
      <Toolbar>
        <div className={classes.title} />
        {user && (
          <Button onClick={logout} title="Sign out" className={classes.button}>
            <ExitToAppIcon />
            {' '}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
