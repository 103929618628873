import React, { useEffect, useState } from 'react';

export default function Timer() {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setDuration(d => d + 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);
  return (
    <div>Current Session: {duration} seconds</div>
  );
}
