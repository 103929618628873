import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles, FormControl, InputLabel, Select,
} from '@material-ui/core';
import Context from '../Context';
import { initMicrophone } from '../audio';
import sentry from '../sentry';

const useStyles = makeStyles((theme) => ({
  selectbox: {
  },
  select: {
    minWidth: 200,
  },
}));

export default function MicSelector({ onChange }) {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setDeviceId, deviceId } = useContext(Context);

  useEffect(() => {
    initMicrophone().then(({ stream, devices: devs }) => {
      const dds = devs.filter((d) => d.kind === 'audioinput');
      setDevices(dds);
      setLoading(false);
      setTimeout(() => {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }, 500);
    }).catch((err) => {
      sentry(err);
    });
  }, []);

  if (loading) return null;

  const selectChange = (event) => {
    setDeviceId(event.target.value);
    onChange && onChange();
  };

  return (
    <FormControl className={classes.selectbox}>
      <InputLabel htmlFor="micselector">Select Microphone</InputLabel>
      <Select
        native
        onChange={selectChange}
        value={deviceId}
        className={classes.select}
      >
        {devices.map((d) => (
          <option value={d.deviceId} key={`-${d.deviceId}`}>{d.label}</option>
        ))}
      </Select>
    </FormControl>
  );
}
