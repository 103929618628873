import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import logo from './logo.png';
import Context from '../src/Context';
import { useAuth } from '../src/auth';
import { useMicSelector } from '../src/audio';
import TopBar from '../src/components/TopBar';
import CallCenterApp from './CallCenterApp';

function App() {
  const { user, signOut, signIn, signing, loading, error, groupId } = useAuth();
  const { deviceId, setDeviceId } = useMicSelector();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    const registerWavCodec = async () => {
      try {
        await register(await connect());
      } catch (err) {
        console.error('error while registering wave encoder', err);
      }
    }
    console.info('Registering wav codecs...');
    registerWavCodec();
  }, []);

  return (
    <div>
      <header>
        <a title="Sanas" href="https://sanas.ai" target="_blank" rel="noreferrer"><img src={logo} alt="logo" /></a>
      </header>

      <Context.Provider value={{ signIn, user, signOut, signing, loading, error, groupId, deviceId, setDeviceId }}>
        <CssBaseline />
        <TopBar />
        <CallCenterApp />
      </Context.Provider>
    </div>
  );
}

export default App;
